import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../app.settings';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    //block UI
    @BlockUI('login') loginBlockUI: NgBlockUI;
    invalidUser: boolean = false;
    username: string;
    password: string;

    constructor(private router: Router,
        private toastrService: ToastrService,
        private loginService: LoginService) { }

    ngOnInit(): void { }

    onClickLogin(): void {
        this.invalidUser = false;
        this.loginBlockUI.start('Please wait....'); // display loader
        this.loginService.login({ username: this.username, password: this.password }).subscribe(
            data => {
                if (data?.token) {
                    localStorage.setItem('access_token', 'Bearer ' + data.token);
                    localStorage.setItem('firstname', data.firstname);
                    this.router.navigate(['/dashboard']);
                }
                this.loginService.stopUiBlocker(this.loginBlockUI);
            }, (error) => {
                if (error?.status == 401) {
                    this.invalidUser = true;
                    this.password = '';
                }
                else
                    this.toastrService.error('Sorry..! unable to login.', 'Error');
                this.loginService.stopUiBlocker(this.loginBlockUI);
            });
    }
}