import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app.settings';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private http: HttpClient) {
    }

    stopUiBlocker(blocker: any) {
        setTimeout(() => {
            blocker.stop();
        }, 400);
    }

    public login(filterParameters: any): Observable<any> {
        return this.http.post(AppSettings.STATIC_REST_ENDPOINT + '/api/auth/signin', filterParameters);
    }
}