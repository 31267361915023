<div class="title-head">Sewage Vehicle Tracker</div>
<div class="login-bg" *blockUI="'login'">
    <div style="text-align: center;">
        <div class="title">Login</div>
        <form name="login-form">
            <div class="row mb-16">
                <mat-form-field style="width: 250px;">
                    <mat-label>Username</mat-label>
                    <input type="text" matInput name="username" [(ngModel)]="username" />
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field style="width: 250px;">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput name="password" [(ngModel)]="password" />
                </mat-form-field>
            </div>
            <div *ngIf="invalidUser" class="error-msg">Invalid username or password.!</div>
            <div class="row mt-20">
                <button style="width: 250px;" (click)="onClickLogin()" mat-raised-button color="primary">Login</button>
            </div>
        </form>
    </div>
</div>