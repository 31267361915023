import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../demo-material-module';
import { BlockUIModule } from 'ng-block-ui';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        BlockUIModule.forRoot(),
    ],
    declarations: [LoginComponent]
})
export class LoginModule { }
