import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit {

  firstname: any = '';
  constructor(private router: Router) { }

  ngOnInit(): void { 
    this.firstname = localStorage.getItem('firstname');;
  }

  onClickSignout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('firstname');
    this.router.navigate(['login']);
  }

}
