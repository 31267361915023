<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <a href="https://wrappixel.com/templates/materialpro-angular-dashboard/" class=" m-r-20 hidden-sm-up" mat-raised-button color="warn">Upgrade To Pro</a> -->
<span *ngIf="firstname" style="font-size: 12px; padding-right: 8px;">Welcome, {{firstname}}</span>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5" style="background-color: #fff;">
    <!-- <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> -->
    <mat-icon style="color: #1E88E5;">person</mat-icon>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button> -->
    <button mat-menu-item (click)="onClickSignout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>